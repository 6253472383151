import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import { mainLayout } from './styles';

import image from '../static/thanks.jpg';
import mailIcon from '../static/mail-icon.png';

const styles = createStyles({
  ...mainLayout(image),
  policy: {
    margin: '0 0 16px 0',
    fontSize: '13px'
  },
  leftText: {
    color: '#fff',
    margin: '0 auto 24px auto',
    maxWidth: '420px',
    '& p' : {
      'marginBottom': '24px'
    }
  },
  middle: {
    textAlign:'center'
  },
  email: {
    fontSize: '18px',
  },
  biggerText: {
    fontSize: '17px'
  }
})

interface Props {
  email: string
}

class Component extends React.Component<Props & WithStyles<typeof styles>> {


  render() {
    const { classes, email } = this.props;
    return (
      <div className={classes.wrapper}>
        <div className={`${classes.rightSide} ${classes.verticallyCentered}`}>
          <div className={classes.rightSideContent}>
            <div className={`${classes.text} ${classes.mainContent}`}>
              <div className={classes.middle}>
                <img src={mailIcon} width="64" height="64" />
                <h1>Check your email!</h1>
                <div className={classes.email}>{email}</div>
              </div>
              <div className={classes.biggerText}>
                <p>We sent you an email with a link to get started. You’ll be in your account in no time. </p>
                <p><i>If you don’t see our email, check the spam folder.</i></p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.sideImage}>

        </div>
      </div>
    );
  }
}
export const Thanks = withStyles(styles)(Component);
