import { utimes } from 'fs';
import * as yup from 'yup';

export interface EmailOnlyFormSchema {
 email: string;
 emailRepeat: string;
}

export const emailOnlyFormSchema = yup.object<EmailOnlyFormSchema>().shape({
  email: yup.string().email('Please enter correct email address').required('You must specify your email'),
  emailRepeat: yup.string().oneOf([yup.ref('email'), null], 'Emails must match')
});

export const emailOnlyFormValues: EmailOnlyFormSchema = {
  email: '',
  emailRepeat: ''
}