import * as yup from 'yup';

export interface EmailFormSchema {
 email: string;
}

export const emailFormSchema = yup.object<EmailFormSchema>().shape({
  email: yup.string().email('Please enter correct email address').required('You must specify your email')
});

export const emailFormValues = {
  email: '',
}