import './App.css';
import React, { Component } from 'react';
import { EmailForm } from './signup/EmailForm';
import { MainForm } from './signup/MainForm';
import { Thanks } from './signup/Thanks';
import logo from './static/tc-logo.svg';
import { EmailOnlyForm } from './signup/EmailOnlyForm';
import { EmailOnlyThanks } from './signup/EmailOnlyThanks';

enum Step {
  Email,
  Main,
  Thanks,
  EmailOnly,
  EmailOnlyThanks
}
interface State {
  email: string;
  plan: string;
  simpleForm: boolean;
  step: Step;
  affiliate?: string;
  partner?: string;
  businessId?: string;
}

class App extends Component {
  state: State = {
    step: Step.Email,
    plan: 'plus',
    simpleForm: false,
    email: ''
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('plan')) {
      this.setState({ plan: queryParams.get('plan') });
    }
    if (queryParams.has('simpleForm')) {
      this.setState({ simpleForm: queryParams.get('simpleForm') === 'true' });
    }
    if (queryParams.has('email')) {
      this.setState({ email: queryParams.get('email'), step: Step.Main });
    }
    if (queryParams.has('a')) {
      this.setState({ plan: 'plus-affiliate', affiliate: queryParams.get('a') });
    }
    if (queryParams.has('partner')) {
      this.setState({ partner: queryParams.get('partner') });
    }
    if (queryParams.has('emailOnly')) {
      this.setState({ step: Step.EmailOnly });
    }
  }

  render() {
    const { step, email, plan, simpleForm, affiliate, partner, businessId } = this.state;
    return (
      <div className="App">
        <a href="https://www.tripcreator.com">
          <img src={logo} className="logo" />
        </a>
        {step === Step.Email &&
          <EmailForm
            emailSelected={email => this.setState({ email, step: Step.Main })}
          />
        }
        {step === Step.Main &&
          <MainForm
            email={email}
            plan={plan}
            simpleForm={simpleForm}
            affiliate={affiliate}
            partner={partner}
            done={() => this.setState({ step: Step.Thanks })}
          />
        }
        {step === Step.Thanks && <Thanks email={email} />}
        {step === Step.EmailOnly &&
          <EmailOnlyForm
            plan={plan}
            affiliate={affiliate}
            partner={partner}
            onDone={(businessId) => this.setState({ step: Step.EmailOnlyThanks, businessId })}
          />
        }
        {step === Step.EmailOnlyThanks && <EmailOnlyThanks businessId={businessId!} />}
      </div>
    );
  }
}

export default App;
