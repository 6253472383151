import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import { mainLayout } from './styles';

import image from '../static/thanks-email.jpg';
import tcIcon from '../static/tc-icon.png';
import { getInviteUrl } from './logic/getInviteUrl';

const styles = createStyles({
  ...mainLayout(image),
  policy: {
    margin: '0 0 16px 0',
    fontSize: '13px'
  },
  waitingText: {
    color: '#C35657',
    fontSize: '12px',
  },
  emailOnly: {
    paddingBottom: 0,
    maxWidth: '240px',
    margin: 'auto'
  },
  loaderContainer: {
    marginBottom: '56px',
    height: '56px',
  },
  tcIcon: {
    marginTop: '16px',
    marginLeft: '8px'
  }
})

interface Props {
  businessId: string
}

class Component extends React.Component<Props & WithStyles<typeof styles>> {

  componentDidMount() {
    setInterval(() => {
      this.checkForInviteUrl()
    }, 1500)
  }

  checkForInviteUrl() {
    const { businessId } = this.props;

    getInviteUrl(businessId)
    .then(inviteUrl => {
      if (inviteUrl) {
        window.location.href = `${inviteUrl}&setupAfterSignup=true`;
      }
    })
  }

  render() {
    const { classes, businessId } = this.props;
    return (
      <div className={classes.wrapper}>
        <div className={`${classes.rightSide} ${classes.verticallyCentered}`}>
          <div className={`${classes.rightSideContent} center`}>
            <div className={`${classes.text} ${classes.mainContent} center`}>
              <h1>We are creating your workspace</h1>
            </div>
            <div className={`${classes.text} ${classes.mainContent} ${classes.emailOnly}`}>
              <div className={`${classes.loaderContainer} center relative`}>
                <div className="tc-loader" />
                <img className={classes.tcIcon} src={tcIcon} width="22" height="32" />
              </div>
              <div className={`${classes.waitingText}`}>
                Great, just few seconds and you’re in and ready to start
              </div>
            </div>
          </div>
        </div>
        <div className={classes.sideImage}>

        </div>
      </div>
    );
  }
}
export const EmailOnlyThanks = withStyles(styles)(Component);
