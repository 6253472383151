import { Field, Form, Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Button, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { TextField } from '../fields/TextField';
import image from '../static/email-form.jpg';
import { emailOnlyFormSchema, EmailOnlyFormSchema, emailOnlyFormValues } from './EmailOnlyForm.schema';
import { validate } from './logic/validate';
import { signup } from './logic/signup';
import { trackGTM, trackIntercomEvent } from './logic/analytics';
import { mainLayout } from './styles';
import { FormMessage } from '../fields/FormMessage';
import { GenericError } from './GenericError';

const styles = createStyles({
  ...mainLayout(image),

  leftText: {
    color: '#fff',
    margin: '0 auto 24px auto',
    paddingLeft: '24px',
    maxWidth: '420px',
    '& p': {
      'marginBottom': '24px'
    }
  },

  emailOnly: {
    paddingBottom: 0,
    maxWidth: '430px',
  },

  policy: {
    margin: '0 0 16px 0',
    fontSize: '13px',
    paddingBottom: '16px',
    paddingTop: '16px'
  },
})

interface Props {
  plan: string;
  affiliate?: string;
  partner?: string;
  onDone: (businessId: string) => void;
}

interface State {
  hasError: boolean
}

class Component extends React.Component<Props & WithStyles<typeof styles>, State> {

  state: State = {
    hasError: false,
  };

  onSubmit(values: EmailOnlyFormSchema, actions :FormikActions<EmailOnlyFormSchema>) {
    actions.setSubmitting(true);
    this.trackSignupStart(values);
    this.setState({ hasError: false });

    const { plan, affiliate, partner } = this.props;

    signup({ email: values.email, plan, affiliate, partner })
    .then(({businessId}) => {
      this.trackSignupComplete(values);
      this.fakeTimerAndReturn(businessId);
    })
    .catch(e => {
      actions.setSubmitting(false);
      this.setState({ hasError: true });
    });
  }

  fakeTimerAndReturn(businessId: string) {
    setTimeout(() => {
      this.props.onDone(businessId)
    }, 1500)
  }

  trackSignupComplete(values: EmailOnlyFormSchema) {
    trackGTM('company_created', {
      email: values.email,
      plan: this.props.plan
    });
  }

  trackSignupStart(values: EmailOnlyFormSchema) {
    const signupEventsOptions = {
      email: values.email,
      plan: this.props.plan
    };
    trackGTM('user_sign_up_completed', signupEventsOptions);
    trackIntercomEvent('user_sign_up_completed', { ...signupEventsOptions });

  }

  formatEmailError(text: string) {
    const showSignin = text === 'Email is already in use';

    return (
      <span>
        {text} {showSignin && <a href="https://admin.tripcreator.io" target="_blank" className="underlined"> Sign in instead? </a>}
      </span>
    );
  }

  validate(values: EmailOnlyFormSchema) {
    return validate([{ 'field': 'email', value: values.email?.trim() }])
      .then(({ valid, errors }) => {
        if (!valid) {
          throw { email: this.formatEmailError(errors[0].error) }
        }
      })
  }

  render() {
    const { classes } = this.props;
    const { hasError } = this.state;

    return (
      <div className={classes.wrapper}>
        <div className={`${classes.rightSide} ${classes.verticallyCentered}`}>
          <div className={classes.rightSideContent}>
            <div className={`${classes.text} ${classes.mainContent} ${classes.emailOnly} center`}>
              <h1>Start your free trial</h1>
              <div>Enter your email to get 30 days of TripCreator free trial.</div>
            </div>
            {hasError && <FormMessage variant="error" message={<GenericError />} onClose={() => this.setState({ hasError: false })} />}
            <Formik
              validate={values => this.validate(values)}
              validateOnBlur={true}
              validateOnChange={false}
              validationSchema={emailOnlyFormSchema}
              initialValues={emailOnlyFormValues}
              onSubmit={(values, actions) => this.onSubmit(values, actions)}
              render={({ isValid, dirty, isSubmitting }) => (
                <Form className={`${classes.form} ${classes.mainContent} ${classes.emailOnly}`}>
                  <Field name="email" label="Your email" component={TextField} />
                  <Field name="emailRepeat" label="Re-enter your email" component={TextField} />
                  <div className={`${classes.policy} center`}>By signing up you are agreeing to the TripCreator's <br/>
                    <a href="https://help.tripcreator.com/en/articles/3154380-terms-of-use" target="_blank">
                      Terms of Service
                    </a> and {' '}
                    <a href="https://help.tripcreator.com/en/articles/3154164-privacy-policy" target="_blank">Privacy Policy</a>.
                  </div>
                  <div className={`${classes.submit} center`}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="submit full-width"
                      disabled={isSubmitting || !dirty}
                    >
                      GET STARTED
                    </Button>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
        <div className={classes.sideImage}>
          <div className={classes.leftText}>
            <h1>A powerful timesaving itinerary builder</h1>
            <p>Automate itinerary and booking processes</p>
            <p>Build travel packages within minutes</p>
            <p>Complete itinerary booking in one place</p>
          </div>
        </div>
      </div>
    );
  }
}
export const EmailOnlyForm = withStyles(styles)(Component);
