import 'url-search-params-polyfill';
import { Field, Form, Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Button, createStyles, LinearProgress, withStyles, WithStyles } from '@material-ui/core';
import { Checkbox } from '../fields/Checkbox';
import { FormMessage } from '../fields/FormMessage';
import { TextField } from '../fields/TextField';
import image from '../static/main-form.jpg';
import { trackGTM, trackIntercomEvent } from './logic/analytics';
import { bootIntercom } from './logic/intercom';
import { signup } from './logic/signup';
import { FieldValidationRequest, validate } from './logic/validate';
import { mainFormSchema, MainFormSchema, mainFormSimpleSchema, mainFormValues } from './MainForm.schema';
import { mainLayout } from './styles';
import { GenericError } from './GenericError';

const styles = createStyles({
  ...mainLayout(image),
  domain: {
    display: 'flex',
    width: '100%',
    '& .field': {
      flexGrow: 1
    },
    '& .text': {
      alignSelf: 'center',
      marginBottom: '24px',
      marginLeft: '8px',
    }
  },
  terms: {
    display: 'flex',
    width: '100%',
    '& .checkbox': {
      paddingLeft: 0
    },
    '& .text': {
      alignSelf: 'center',
    }
  },
  progressInactive: {
    visibility: 'hidden'
  },
  progress: {
    marginTop: '8px',
    marginBottom: '8px',
  }
});

interface Props {
  email: string;
  plan: string;
  affiliate?: string;
  partner?: string;
  simpleForm: boolean;
  done: () => void;
}

interface State {
  hasError: boolean;
}

class Component extends React.Component<Props & WithStyles<typeof styles>, State> {

  state: State = {
    hasError: false,
  };

  componentDidMount() {
    trackGTM('user_sign_up_started', { email: this.props.email, plan: this.props.plan });
    bootIntercom(this.props.email);
    trackIntercomEvent('user_sign_up_started', { email: this.props.email, plan: this.props.plan });
  }

  // onBlur for fields would be a better place, but could not get it working.
  trim(values: MainFormSchema) {
    Object.keys(values).forEach(k => {
      if (typeof values[k] === "string") {
        values[k] = values[k].trim();
      }
    });
    return values;
  }

  onSubmit(values: MainFormSchema, actions: FormikActions<MainFormSchema>) {
    const { email, done, plan, affiliate, partner } = this.props;
    actions.setSubmitting(true);

    const signupEventsOptions = {
      email: this.props.email,
      firstName: values.firstName,
      lastName: values.lastName,
      businessTitle: values.companyName,
      domain: values.username ? `https://${values.username}.tripcreator.io` : undefined,
      plan: this.props.plan
    };
    trackGTM('user_sign_up_completed', signupEventsOptions);
    trackIntercomEvent('user_sign_up_completed', { ...signupEventsOptions });

    signup({ ...values, email, plan, affiliate, partner }).then(() => {
      trackGTM('company_created', {
        email: this.props.email,
        plan: this.props.plan
      });
      done();
    }).catch(e => {
      actions.setSubmitting(false);
      this.setState({ hasError: true });
    });
  }

  validate(values: MainFormSchema) {

    const { simpleForm } = this.props;
    if (simpleForm) {
      return {};
    }
    this.setState({ hasError: false });
    const checkValues: FieldValidationRequest[] = [];
    ['username', 'companyName'].forEach(f => {
      if (values[f]) {
        checkValues.push({ 'field': f, value: values[f] });
      }
    });
    if (!checkValues.length) {
      return {};
    }
    return validate(checkValues)
      .then(({ valid, errors }) => {
        if (!valid) {
          throw errors.reduce((acc, e) => {
            acc[e.field] = e.error;
            return acc;
          }, {});
        }
      });
  }

  render() {
    const { classes, simpleForm } = this.props;
    const { hasError } = this.state;
    return (
      <div className={classes.wrapper}>
        <div className={classes.rightSide}>
          <div className={classes.rightSideContent}>
            <div className={`${classes.text} ${classes.mainContent}`}>
              <h1>You're almost there!</h1>
              <div>Please take a minute to complete your account settings</div>
            </div>
            {hasError && <FormMessage variant="error" message={<GenericError />} onClose={() => this.setState({ hasError: false })} />}
            <Formik
              validate={values => this.validate(this.trim(values))}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={simpleForm ? mainFormSimpleSchema : mainFormSchema}
              initialValues={mainFormValues}
              onSubmit={(values, actions) => this.onSubmit(this.trim(values), actions)}
              render={({ isValid, dirty, isSubmitting }) => (
                <Form className={`${classes.form} ${classes.mainContent}`} autoComplete="false">
                  <Field name="firstName" label="First Name" component={TextField} />
                  <Field name="lastName" label="Last Name" component={TextField} />
                  {!simpleForm &&
                    <React.Fragment>
                      <Field name="companyName" label="Company Name" component={TextField} />
                      <div className={classes.domain}>
                        <Field className="field" name="username" label="Team domain name" component={TextField} />
                        <div className="text">.tripcreator.io</div>
                      </div>
                      <Field name="password" label="Password" type="password" component={TextField} />
                    </React.Fragment>
                  }
                  <Field name="phone" label="Phone number (e.g. +1-541-754-3010)" component={TextField} />
                  <div className={classes.terms}>
                    <Field name="terms" component={Checkbox} className="checkbox" />
                    <div className={classes.policy}>
                      I agree to TripCreator's {' '}<a href="https://help.tripcreator.com/en/articles/3154380-terms-of-use" target="_blank">
                        Terms of Service
                      </a> {' '} and {' '}
                      <a href="https://help.tripcreator.com/en/articles/3154164-privacy-policy" target="_blank">Privacy Policy</a>.
                    </div>
                  </div>
                  <LinearProgress color="secondary" className={`${classes.progress} ${isSubmitting ? '' : classes.progressInactive}`} />
                  <div className={classes.submit}>
                    <Button
                      type="submit"
                      variant="contained" color="secondary"
                      disabled={isSubmitting || !dirty}
                      className="submit"
                    >
                      GET STARTED
                    </Button>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
        <div className={classes.sideImage}>

        </div>
      </div>
    );
  }
}

export const MainForm = withStyles(styles)(Component);
