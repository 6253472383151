import { BASE_HOST } from "./config";

export interface FieldValidationRequest {
  field: string;
  value: string;
}

export function validate(fields: FieldValidationRequest[]) {
  return fetch(`${BASE_HOST}/validate`, {
    method: 'POST',
    body: JSON.stringify(fields)
  })
  .then(res => res.json())
}