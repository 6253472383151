
export function trackGTM(event: string, data: {[key: string]: any}) {
  const win = window as any;
  const gtmEvent = {
    event,
    ...data
  }
  if (win.dataLayer) {
    win.dataLayer.push(gtmEvent)
  } else {
    win.dataLayer = [gtmEvent];
  }
}

export function trackIntercomEvent(name, event) {
  const { Intercom } = window as any;
  if (Intercom) {
    Intercom('trackEvent', name, event);
  }
}
