import { getFirstTouchUTMParams } from "./utm";

declare var Intercom;
export function bootIntercom(email: string) {
  if (Intercom) {
    Intercom('boot', {
      app_id: 'mjmo255t',
      email,
      hide_default_launcher: true,
      ...getFirstTouchUTMParams()
    });
  }
}