import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { Button, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { TextField } from '../fields/TextField';
import image from '../static/email-form.jpg';
import { emailFormSchema, EmailFormSchema, emailFormValues } from './EmailForm.schema';
import { validate } from './logic/validate';
import { mainLayout } from './styles';

const styles = createStyles({
  ...mainLayout(image),

  leftText: {
    color: '#fff',
    margin: '0 auto 24px auto',
    paddingLeft: '24px',
    maxWidth: '420px',
    '& p': {
      'marginBottom': '24px'
    }
  },
})

interface Props {
  emailSelected: (email: string) => void;
}

class Component extends React.Component<Props & WithStyles<typeof styles>> {

  onSubmit(values: EmailFormSchema, actions) {
    this.props.emailSelected(values.email);
  }

  formatEmailError(text: string) {
    const showSignin = text === 'Email is already in use';

    return (
      <span>
        {text} {showSignin && <a href="https://admin.tripcreator.io" target="_blank" className="underlined"> Sign in instead? </a>}
      </span>
    );
  }

  validate(values: EmailFormSchema) {
    return validate([{ 'field': 'email', value: values.email?.trim() }])
      .then(({ valid, errors }) => {
        if (!valid) {
          throw { email: this.formatEmailError(errors[0].error) }
        }
      })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <div className={`${classes.rightSide} ${classes.verticallyCentered}`}>
          <div className={classes.rightSideContent}>
            <div className={`${classes.text} ${classes.mainContent}`}>
              <h1>Get started</h1>
              <div>Sign up and start using TripCreator. No credit card needed.</div>
            </div>
            <Formik
              validate={values => this.validate(values)}
              validateOnBlur={true}
              validateOnChange={false}
              validationSchema={emailFormSchema}
              initialValues={emailFormValues}
              onSubmit={(values, actions) => this.onSubmit(values, actions)}
              render={({ isValid, dirty, isSubmitting }) => (
                <Form className={`${classes.form} ${classes.mainContent}`}>
                  <Field name="email" label="Email" component={TextField} />
                  <div className={classes.policy}>TripCreator's{' '}
                    <a href="https://help.tripcreator.com/en/articles/3154380-terms-of-use" target="_blank">
                      Terms of Service
                    </a> and {' '}
                    <a href="https://help.tripcreator.com/en/articles/3154164-privacy-policy" target="_blank">Privacy Policy</a>.
                  </div>
                  <div className={classes.submit}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="submit"
                      disabled={isSubmitting || !dirty}
                    >
                      CREATE ACCOUNT
                    </Button>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
        <div className={classes.sideImage}>
          <div className={classes.leftText}>
            <h1>A powerful timesaving itinerary builder</h1>
            <p>Automate itinerary and booking processes</p>
            <p>Build travel packages within minutes</p>
            <p>Complete itinerary booking in one place</p>
          </div>
        </div>
      </div>
    );
  }
}
export const EmailForm = withStyles(styles)(Component);
