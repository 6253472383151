import * as yup from 'yup';

export interface MainFormSchema {
  firstName: string;
  lastName: string;
  companyName?: string;
  username?: string;
  password?: string;
  phone: string;
  terms: boolean;
}

const baseSchema = {
  firstName: yup.string().trim().required('You must specify your First name'),
  lastName: yup.string().trim().required('You must specify your Last name'),
  phone: yup.string().trim().required('You must specify phone number'),
  terms: yup.boolean().required('You must agree to Terms of Service and Privacy Policy')
}

export const mainFormSchema = yup.object<MainFormSchema>().shape({
  ...baseSchema,
  companyName: yup.string().trim().required('You must specify your Company name'),
  username: yup.string().trim().required('You must specify your Team domain name').matches(
    /^[A-Za-z0-9]/,
    {
      message: 'Domain can only contain letters and numbers',
      excludeEmptyString: true,
    },
  ),
  password: yup.string().trim().required('You must specify your password').min(5, 'Password must be at least 5 characters'),
});

export const mainFormSimpleSchema = yup.object<MainFormSchema>().shape(baseSchema);

export const mainFormValues: MainFormSchema = {
  firstName: '',
  lastName: '',
  companyName: undefined,
  username: undefined,
  password: undefined,
  phone: '',
  terms: true
}