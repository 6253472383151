// NIH https://github.com/stackworx/formik-material-ui/blob/master/src/TextField.tsx
import * as React from 'react';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField';
import { FieldProps, getIn } from 'formik';
import { withStyles, createStyles, WithStyles } from '@material-ui/core';
import { Omit } from './types';

interface Props
  extends FieldProps,
    Omit<MuiTextFieldProps, 'error' | 'name' | 'onChange' | 'value'> {}

type TextFieldProps = Props & WithStyles<typeof styles>

const fieldToTextField = ({
  field,
  form,
  variant = 'outlined',
  disabled = false,
  ...props
}: TextFieldProps): MuiTextFieldProps => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return {
    ...props,
    ...field,
    // Hack to work around type issue
    // See: https://github.com/Microsoft/TypeScript/issues/28791
    variant: variant as any,
    error: showError,
    helperText: showError ? fieldError : props.helperText || ' ',
    disabled: isSubmitting || disabled,
    autoComplete: "off",
    classes: undefined
  };
};

const styles = createStyles({
  root: {
    marginTop:'8px'
  },
  cssFocused: {},
  notchedOutline: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#595E60',
    },
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#595E60',
    },
  },
})

export const Component: React.ComponentType<TextFieldProps> = ({ children, ...props }: TextFieldProps) => (
  <MuiTextField 
    {...fieldToTextField(props)} 
    children={children} 
    className={props.classes.root}
    InputProps={{
      classes: {
        root: props.classes.cssOutlinedInput,
        focused: props.classes.cssFocused,
      },
    }}
    InputLabelProps={{
      classes: {
        root: props.classes.cssLabel,
        focused: props.classes.cssFocused,
      },
    }}
    />
);

export const TextField = withStyles(styles)(Component);

TextField.displayName = 'FormikMaterialUITextField';
