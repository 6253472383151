import { BASE_HOST } from "./config";

export interface BusinessSignupRequest {
  email: string;
  plan: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  username?: string;
  password?: string;
  phone?: string;
  affiliate?: string;
  partner?: string;
}

export interface BusinessSignupResponse {
  businessId: string;
}

export function signup(req: BusinessSignupRequest): Promise<BusinessSignupResponse> {
  return fetch(`${BASE_HOST}/signup`, {
    method: 'POST',
    body: JSON.stringify(req)
  })
  .then(res => {
    if (res.status !== 200) {
      throw 'Non 200 status';
    }
    return res.json()
  })
}
