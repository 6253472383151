import Cookies from 'js-cookie'

const targetParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term',  'utm_content' ];

export function getFirstTouchUTMParams() {
  let params = {}
  targetParams.forEach(param => {
    const cookie = Cookies.get(`tc_ft_${param}`);
    if (cookie) {
      params[`ft_${param}`] = cookie;
    }
  })
  return params;
}