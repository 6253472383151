import { createStyles } from "@material-ui/core";

export const mainLayout = (image: string) => createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    alignItems: 'center'
  },
  mainContent: {
    maxWidth: '520px',
    margin: '0 auto',
    padding: '24px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  rightSide: {
    width: '50%',
    height: '100vh',
    overflowY: 'auto',
    display: 'flex'
  },
  verticallyCentered: {
    alignItems: 'center'
  },
  rightSideContent: {
    width: '100%'
  },
  text: {
    marginTop: '40px'
  },
  sideImage: {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    width: '50%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end'
  },
  submit: {
    textAlign: 'right',
    marginBottom: '32px'
  },
  policy: {
    margin: '0 0 16px 0',
    fontSize: '13px',
    paddingTop: '16px'
  },
  '@media (max-width: 768px)': {
    wrapper: {
      flexDirection: 'column',
      height: 'auto'
    },
    rightSide: {
      width: '100%',
      height: 'auto',
      overflowY: 'inherit'
    },
    sideImage: {
      width: '100%',
      height: '450px'
    },
  }
});
